import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import { jsx as ___EmotionJSX } from "@emotion/core";

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from 'react';
import Highlight, { defaultProps } from 'prism-react-renderer';
import theme from 'prism-react-renderer/themes/vsDark';

var Code = function Code(_ref) {
  var codeString = _ref.codeString,
      language = _ref.language,
      props = _objectWithoutPropertiesLoose(_ref, ["codeString", "language"]);

  return ___EmotionJSX(Highlight, _extends({}, defaultProps, {
    code: codeString,
    language: language,
    theme: theme
  }), function (_ref2) {
    var className = _ref2.className,
        style = _ref2.style,
        tokens = _ref2.tokens,
        getLineProps = _ref2.getLineProps,
        getTokenProps = _ref2.getTokenProps;
    return ___EmotionJSX("pre", {
      className: className,
      style: style
    }, tokens.map(function (line, i) {
      return ___EmotionJSX("div", getLineProps({
        line: line,
        key: i
      }), line.map(function (token, key) {
        return ___EmotionJSX("span", getTokenProps({
          token: token,
          key: key
        }));
      }));
    }));
  });
};

export default Code;