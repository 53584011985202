import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import { jsx as ___EmotionJSX } from "@emotion/core";

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import { preToCodeBlock } from 'mdx-utils';
import Code from './CodeBlock';
import SEO from '../seo';
import { PostWrapper } from './styles'; // components is its own object outside of render so that the references to
// components are stable

var components = {
  pre: function pre(preProps) {
    var props = preToCodeBlock(preProps); // if there's a codeString and some props, we passed the test

    if (props) {
      return ___EmotionJSX(Code, props);
    } // it's possible to have a pre without a code in it


    return ___EmotionJSX("pre", preProps);
  }
};

var PostLayout = function PostLayout(_ref) {
  var children = _ref.children,
      pageContext = _ref.pageContext,
      data = _ref.data,
      rest = _objectWithoutPropertiesLoose(_ref, ["children", "pageContext", "data"]);

  console.log(pageContext, data);
  return ___EmotionJSX(MDXProvider, {
    components: components
  }, ___EmotionJSX(SEO, {
    title: pageContext.frontmatter.title,
    description: pageContext.frontmatter.description
  }), ___EmotionJSX(PostWrapper, null, children), ";");
};

export default PostLayout;